import * as React from 'react'
import { useEffect, useState } from 'react'

import Layout from 'layouts/main'
import AboutHero from '../components/about/aboutHero'
import DeanAndFaculty from '../components/about/dean&faculty'
import ManagingTeam from '../components/about/managingTeam'
import fetch from 'node-fetch'
import TrusteeAndMentor from '../components/about/trustee&mentor'
import { config } from '../config'
import BoardMembers from '../components/about/boardMembers'
import AboutDiagram from '../components/about/aboutDiagram'

const AboutPage = () => {
  const [team, setTeam] = useState({
    deanAndFaculty: [],
    managingTeam: [],
    boardMembers: [],
    honoredMembers: [],
    eir: [],
  })
  const [mentors, setMentors] = useState([])

  useEffect(() => {
    fetch(`${config.API_BASE_URL}/team/forAbout`).then(response => {
      response.json().then(team => {
        setTeam({
          deanAndFaculty: team.deanAndFaculty,
          managingTeam: team.managingTeam,
          boardMembers: team.boardMembers,
          honoredMembers: team.honoredMembers,
          eir: team.eir,
        })
      })
    })
    fetch(`${config.API_BASE_URL}/users/subscribed`).then(response => {
      response.json().then(team => {
        setMentors(team)
      })
    })
  }, [])

  let boardMembers = team.boardMembers
  let eir = team.eir
  let deanAndFaculty = team.deanAndFaculty
  let honoredMembers = team.honoredMembers
  return (
    <div className='Homepage About'>
      <Layout title="About">
        <AboutHero />
        <AboutDiagram />
        <BoardMembers members={honoredMembers} title={'Honored Members'}/>
        <BoardMembers members={boardMembers} title={'Board Members'}/>
        <DeanAndFaculty members={deanAndFaculty} />
        <BoardMembers members={eir} title={'EIR'}/>
        <ManagingTeam members={team.managingTeam} />
      </Layout>
    </div>

  )
}

export default AboutPage
