import * as React from 'react'
import styled from 'styled-components'
import { H1, Paragraph } from '../styled/typography'
import { fadeInText1, fadeInText2, fadeInText3 } from '../styled/animatedText'
import { SCREEN } from 'styles/screens'
import { FixedContainer } from '../styled/containers'
import { getTranslation } from '../../data/dictionary'
import RenderHtml from '../shared/renderHtml'

const StyledContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: flex-start;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FadeInText1 = styled(H1)`
  font-size: 6.5vw;
  letter-spacing: 0;
  transition: all 0.2s ease-in-out;
  user-select: none;
  animation: ${fadeInText1} 1s ease both;
`

const FadeInText2 = styled(H1)`
  font-size: 6.5vw;
  letter-spacing: 0;
  transition: all 0.2s ease-in-out;
  user-select: none;
  animation: ${fadeInText2} 1.5s ease both;
`

const FadeInText3 = styled(H1)`
  font-size: 6.5vw;
  letter-spacing: 0;
  transition: all 0.2s ease-in-out;
  user-select: none;
  animation: ${fadeInText3} 1.5s ease both;
  &:hover {
    color: var(--secondary);
  }
`

const Article = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-direction: column;
    margin-top: 24px;
    & > p {
      margin-top: 24px;
    }
  }
  & > p {
    flex-basis: 48%;
  }
  & > div {
    flex-basis: 48%;
  }
  & h4 {
  }
`

const OurMission = styled.div`
  > h5 {
    display: none;
  }
  > ul {
    margin: 0;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > ul {
      > li {
        margin: 16px 0;
      }
    }
    > h5 {
      display: block;
    }
  }
`

const AboutHero = () => {
  return (
    <Container>
      <StyledContainer>
        <div>
          <FadeInText1 inverted>
            {getTranslation('about_hero_heading_line_1', 'From The')}{' '}
          </FadeInText1>
          <FadeInText2 inverted>
            {getTranslation('about_hero_heading_line_2', 'Creators Of')}
          </FadeInText2>
          <FadeInText3>
            {getTranslation('about_hero_heading_line_3', 'Awesome foundation')}
          </FadeInText3>
        </div>
        <Article>
          <Paragraph>
            <RenderHtml html={getTranslation(
              'about_hero_column_1',
              'Based on Awesome Foundation’s 8 years of development experiences, we\n' +
              '            are sure that the professional development of education in our\n' +
              '            fields should be motivated and promoted by the community themselves.\n' +
              '            So the general idea of the BuildUp platform is to build a community\n' +
              '            of 200 top CEOs, Directors, and Professionals from the Armenian IT\n' +
              '            community worldwide, to develop local talents in Armenia. Literally,\n' +
              '            we will ask the trustees to invest $200 per month for the next two\n' +
              '            years, to build a community that they can hire right from Bootcamp\n' +
              '            graduation day.', false
            )} />
          </Paragraph><Paragraph>
            <RenderHtml html={getTranslation(
              'about_hero_column_2',
              'Based on Awesome Foundation’s 8 years of development experiences, we\n' +
              '            are sure that the professional development of education in our\n' +
              '            fields should be motivated and promoted by the community themselves.\n' +
              '            So the general idea of the BuildUp platform is to build a community\n' +
              '            of 200 top CEOs, Directors, and Professionals from the Armenian IT\n' +
              '            community worldwide, to develop local talents in Armenia. Literally,\n' +
              '            we will ask the trustees to invest $200 per month for the next two\n' +
              '            years, to build a community that they can hire right from Bootcamp\n' +
              '            graduation day.', false
            )} />
          </Paragraph>
        </Article>
      </StyledContainer>
    </Container>
  )
}

export default AboutHero